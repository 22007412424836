////////////////////////////////////
//Patron de colores
////////////////////////////////////
$principal: #8a2fbb;
$secundario: #01335c;
$terciario: #eea723;
$cuarto: #2931fa;
$bordercolor: #ff00ff;
$texto: #494949;

///////////////////////////////////////
//Modificando variables de Bootstrap
//////////////////////////////////////

$primary: $principal; //Color principaL
$secondary: $secundario; //Color secundario
$warning: $terciario;
$font-family-base: "Poppins", sans-serif; //Tipo de letra
$enable-negative-margins: true;

///////////////////////////////////////
// Fuente del certificado
//////////////////////////////////////

@font-face {
  font-family: 'SharpGrotesk';
  src: url('./assets/fonts/SharpGrotesk-Book25.woff') format('woff');
}

// //Componente Buttoms
$btn-border-radius: 0.8rem;
$input-border-radius: 0.9rem;
$border-color: $bordercolor;
$input-placeholder-color: white;

$btn-padding-y: 0.275rem;
$btn-padding-x: 0.275rem;
// //Componente Modal
$modal-content-bg: $cuarto;
$modal-content-color: white;
$modal-content-border-radius: 10px;
$modal-header-padding: 0px;
$btn-close-color: $primary;
$btn-close-bg: url("../../../src/assets/img/icon-close.png");
$btn-close-opacity: 1;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import "~bootstrap/scss/bootstrap";
///////////////////////////////////////
// Estilos generales
//////////////////////////////////////

.betterPopups {
  background-color: $cuarto;
  color: white;
}
.btn {
  font-size: 10px;
  font-weight: bold;
  min-width: 90px;
}

.btnXs {
  min-width: 70px;
}


.btn:hover {
  box-shadow: 0 5px 26px 0 rgba(255, 255, 255, 0.6);
}

.container {
  max-width: 100%;
}

.colorBorder {
  color: $bordercolor;
}

.l1 {
  background-color: #dc273c;
  width: "100%";
  height: "500px";
}

.l2 {
  background-color: #2136f6;
  width: "100%";
  height: "500px";
}

.swal2-popup {
  border-radius: 40px !important;
}

.canvas {
  position: fixed;
  top: 0;
  left: 0;

  width: 50%;

  background-color: tomato;

  display: none;
}

///////////////////////////////////////
//  Estilos para el login
//////////////////////////////////////
.backgroundLogin {
  background: url("../src/assets/img/background-login-sm.jpg") center
    center/cover fixed;
  overflow: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.textLogin .info1 {
  color: white;
  font-weight: bold;
  font-size: 18px;
  //font-size: 2vw; //calc(1em + 1vw);;
}

.login__help{
  position: static;
  margin-top: 5px;
}

.textLogin .info2 {
  color: #2136f6;
  font-weight: bold;
  font-size: 18px;
  //font-size: 2vw; //calc(1em + 1vw);
}

.inputLogin {
  background-color: transparent;
  border: 2px solid white;
  margin: 10px auto;
}

.inputCheck {
  background: transparent;
  border: 2px solid white;
  border-radius: 0px;
}

.backgroundLogin .form-check-label {
  font-size: 8px;
}

.backgroundLogin .expText {
  font-size: 11px;
}

.backgroundLogin .cweb {
  display: flex;
  justify-content: center;
  font-size: 8px;
}

.footer {
  margin: auto auto 0 auto;
}

.backgroundLogin .formAuth {
  display: flex;
  flex-flow: column;
}

.backgroundLogin .formAuth .btnLogin {
  margin: auto auto auto 0;
  background-image: linear-gradient(to left, #c433f4 0%, #8022be 100%);
  border: 0px;
}

.backgroundLogin .formAuth .btnLogin:disabled {
  margin: auto auto auto 0;
  background-image: linear-gradient(to left, #c5c5c5 0%, #838383 100%);
}


@media (min-width: 815px) {
  //@include media-breakpoint-up(md) {
  .backgroundLogin {
    background: url("../src/assets/img/background-login-md.jpg") center
      center/cover fixed;
  }


  .login__help{
    position: absolute;
    margin-top: 30px;
  }

  .textLogin .info1 {
    font-size: 18px;
    //font-size: 2vw; //calc(1em + 1vw);;
  }

  .textLogin .info2 {
    font-size: 18px;
    //font-size: 2vw; //calc(1em + 1vw);
  }

  .backgroundLogin .form-check-label {
    font-size: 10px;
  }

  .backgroundLogin .cweb {
    display: flex;
    justify-content: flex-start;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  //@include media-breakpoint-up(lg) {
  .backgroundLogin {
    background: url("../src/assets/img/background-login.jpg") center
      center/cover hidden;
  }

  .textLogin .info1 {
    font-size: 25px;
    //font-size: 2vw; //calc(1em + 1vw);;
  }

  .textLogin .info2 {
    font-size: 25px;
    //font-size: 2vw; //calc(1em + 1vw);
  }

  .backgroundLogin .form-check-label {
    font-size: 10px;
  }

  .backgroundLogin .cweb {
    display: flex;
    justify-content: flex-start;
    font-size: 10px;
  }
}

///////////////////////////////////////
// Page map
//////////////////////////////////////
.backgroundMap {
  background: url("../src/assets/img/background-map-sm.jpg") center 20%/cover
    fixed;
}

.mapBtnSecundary {
  //display: scroll;
  position: fixed;
  bottom: 45%;
  right: 35%;
  width: 130px;

  animation-name: pump;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 0.7s;
  animation-delay: 0;
}

.mapBtnPrimary {
  //display: scroll;
  position: fixed;
  bottom: 60%;
  left: 2%;
  width: 130px;

  animation-name: pump;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 0.7s;
  animation-delay: 0;
}

@keyframes pump {
  0% {
    width: 135px;
  }
  50% {
    width: 130px;
  }
  100% {
    width: 135px;
  }
}

.videoMapContainer {
  border: 2px solid #cecece;
  position: fixed;
  width: 30%;
  bottom: 2%;
  right: 2%;
  height: 35%;
}

.veideMapBtn {
  background-color: $primary;
  font-weight: bold;
  color: white;
  height: 18px;
  font-size: 12px;
}

.contendVideoMap {
  height: 85%;
  background-color: #00000075;
}

#userPhoto {
  display: none;
}

@media (min-width: 815px) {
  //@include media-breakpoint-up(md) {
  .backgroundMap {
    background: url("../src/assets/img/background-map-md.jpg") center
      center/cover fixed;
  }

  .mapBtnSecundary {
    //display: scroll;
    bottom: 45%;
    right: 40%;
  }

  .videoMapContainer {
    width: 25%;
    height: 30%;
    overflow: hidden;
  }

  .contendVideoMap {
    height: 100%;
  }

}

@media (min-width: 992px) {
  //@include media-breakpoint-up(lg) {
  .backgroundMap {
    background: url("../src/assets/img/background-map.jpg") center center/cover
      fixed;
  }

  .mapBtnSecundary {
    bottom: 45%;
    right: 35%;
    width: 170px;
  }

  @keyframes pump {
    0% {
      width: 235px;
    }
    50% {
      width: 230px;
    }
    100% {
      width: 235px;
    }
  }

  .mapBtnPrimary {
    width: 170px;
  }

  .btn {
    font-size: 15px;
    font-weight: bold;
    min-width: 90px;
  }

  .videoMapContainer {
    width: 25%;
    height: 30%;
  }

  .contendVideoMap {
    height: 92%;
  }
  
}

///////////////////////////////////////
// Page lobby
//////////////////////////////////////
.backgroundLobbyStand {
  background: url("../src/assets/img/background-lobby-sm.jpg") center
    center/cover fixed;
}

.lobbyBtnSecundary {
  display: scroll;
  position: fixed;
  bottom: 6%;
  left: 10%;
  width: 140px;
}

.loobyBtnLeft {
  display: scroll;
  position: fixed;
  bottom: 25%;
  left: 10%;
  width: 170px;
}

.loobyBtnRight {
  display: scroll;
  position: fixed;
  bottom: 25%;
  right: 10%;
  width: 170px;
}

@media (min-width: 815px) {
  //@include media-breakpoint-up(md) {
  .backgroundLobbyStand {
    background: url("../src/assets/img/background-lobby-md.jpg") center
      center/cover fixed;
  }
  .tarjeta{
    .nameCont {
      h2 {
        font-size: 1.3vw;
      }
    }  
  }
}

@media (min-width: 992px) {
  //@include media-breakpoint-up(lg) {
  .backgroundLobbyStand {
    background: url("../src/assets/img/background-lobby.jpg") center
      center/cover fixed;
  }

  .tarjeta{
    .nameCont {
      h2 {
        font-size: 1.3vw;
      }
    }  
  }
}

///////////////////////////////////////
// Page Cover
//////////////////////////////////////

.coverCont {
  width: 100vw;
  height: 100vh;

  margin: auto;
  display: flex;
}

.coverCont .container {
  width: 100%;
  height: 100%;

  display: flex;

  overflow: auto;
}

.coverCont .container h1 {
  width: 100%;

  text-align: center;
  margin: auto;
  color: #FFF;
  font-size: 30px;
  line-height: 30px;

  overflow: auto;
}

///////////////////////////////////////
// Page Stands
//////////////////////////////////////

.standBtnProduct {
  display: scroll;
  position: fixed;
  top: 20%;
  right: 12%;
  width: 120px;
}

.standBtnBrochure {
  display: scroll;
  position: fixed;
  bottom: 25%;
  right: 18%;
  width: 120px;
}

.standBtnPdf {
  display: scroll;
  position: fixed;
  bottom: 27%;
  left: 18%;
  width: 120px;
}

.standBtnBack {
  display: scroll;
  position: fixed;
  bottom: 5%;
  left: 7%;
}

.videoStand {
  display: scroll;
  position: fixed;
  top: 18%;
  left: 28%;
  //background-color: white;
  width: 38%;
  height: 40%;
  video{
    border-radius: 20px;
  }
}

///////////////////////////////////////
// Page Stand The Lab
//////////////////////////////////////
.backgroundTheLab {
  background: url("../src/assets/img/background-stand-lab-sm.jpg") center
    center/cover fixed;
}

@media (min-width: 815px) {
  //@include media-breakpoint-up(md) {
  .backgroundTheLab {
    background: url("../src/assets/img/background-stand-lab-md.jpg") center
      center/cover fixed;
  }
}

@media (min-width: 992px) {
  //@include media-breakpoint-up(lg) {
  .backgroundTheLab {
    background: url("../src/assets/img/background-stand-lab.jpg") center
      center/cover fixed;
  }
}

///////////////////////////////////////
// Page Stand EpiCx
//////////////////////////////////////
.backgroundEpiCx {
  background: url("../src/assets/img/background-stand-epic-sm.jpg") center
    center/cover fixed;
}

@media (min-width: 815px) {
  //@include media-breakpoint-up(md) {
  .backgroundEpiCx {
    background: url("../src/assets/img/background-stand-epic-md.jpg") center
      center/cover fixed;
  }

  .videoStand {
    top: 15%;
    left: 24%;
    width: 45%;
    height: 45%;
  }
}

@media (min-width: 992px) {
  //@include media-breakpoint-up(lg) {
  .backgroundEpiCx {
    background: url("../src/assets/img/background-stand-epic.jpg") center
      center/cover fixed;
  }
  .standBtnProduct {
    right: 25%;
    width: 170px;
  }

  .standBtnBrochure {
    bottom: 24%;
    right: 27%;
    width: 170px;
  }

  .standBtnPdf {
    bottom: 16%;
    width: 170px;
  }

  .standBtnBack {
    width: 170px;
  }

  .videoStand {
    top: 15%;
    left: 29%;
    width: 34%;
    height: 45%;
  }
}

///////////////////////////////////////
//  Estilos para el auditorium
//////////////////////////////////////
.auditorioLogo {
  width: 180px;
}

.auditorioLogoRoche {
  width: 60px;
  float: right;
}

.direct {
  height: 100%;
  border-radius: 20px;
}

.pestanas {
  float: top;
  font-size: 9px;
  font-weight: bold;
}

.pestanas .detalle {
  list-style-type: none;
  float: right;
  text-align: center;
  font-size: 12px;
  // margin: 0px 15px 0px -0px;
  background: $primary;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0px 15px 0px 15px;
}


@media (min-width: 420px) {
  .pestanas .detalle {
    font-size: 0.9vw;
    padding: 1px 15px 1px 15px;
  }
  .pestanas{
    margin-left: 0px !important;
  }
}

// .pestanas .chats {
//   margin: 0px;
// }
.pestanas a:link {
  text-decoration: none;
  color: white;
}

.transmision{
  display: flex;
  flex-direction: row;
  align-items: center;
  .pRoom{
    margin: 5px;
    color: white;
    font-size: 9px;
  }
}



.contenidopestanas {
  clear: both;
  background: #FFF0;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  margin: auto;
}

.contenidopestanas .iframeCont {
  width: 100%;

  margin: auto;

  display: flex;
}

.contenidopestanas .iframeCont iframe {
  width: 98%;
  aspect-ratio: 16/9;

  border-radius: 10px;

  background-color: #FFF;

  margin: auto;

  overflow: hidden;
}

.contenidopestanas .bannerContainer {
  width: 98%;

  margin: 10px auto;

  border-radius: 10px;
}
/* ----------------------------------------- */

.chatSpeacher {
  min-height: 50px;
  position: relative;
  padding: 5px;
  background: #FFF;
}

.chatSession {
  height: 180px;
  position: relative;
  padding: 5px;
}

.inputChat {
  background-color: #e8e8eb;
  border: 2px solid white;
  font-size: 4vw;
  color: #01335C;
}

.inputChat::placeholder {
  font-size: 4vw;
  color: #01335C99;
}

@media (min-width: 420px) {
  .inputChat {
    font-size: 1vw;
    padding: 3% 12% 3% 3%;
  }

  .inputChat::placeholder {
    font-size: 1vw;
  }
}

.send {
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(0%, -50%);
  z-index: 10;
  padding: 0;

  width: 30px;
  height: 30px;
}

.send img {
  width: 100%;
  height: 100%;
}

/* ---------------------------------------- */

.roomContainer .container .modal {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: #FFF7;

  margin: auto;

  display: flex;

  z-index: 10000;
}

.roomContainer .container .modal .close {
  position: absolute;
  right: 2%;
  top: 2%;

  font-weight: 800;
  font-size: 30px;
  color: #8022be;

  cursor: pointer;
}

.roomContainer .container .modal .modalContainer {
  width: 90%;
  height: 90%;

  margin: auto;

  background-color: #FFF;

  border-radius: 10px;

  overflow: hidden;
}

.roomContainer .container .modal .modalContainer iframe {
  width: 100%;
  height: 100%;

  margin: auto;
}

.roomContainer .iframeChat {
  width: 100%;
  height: 60vh;

  margin: auto;

  border-radius: 0px 0px 10px 10px;
}

@include media-breakpoint-up(sm) {
  .chatSpeacher {
    min-height: 50px;
  }

  .chatSession {
    min-height: 80px;
  }
}

@include media-breakpoint-up(md) {
  .chatSpeacher {
    min-height: 50px;
  }

  .chatSession {
    height: 180px;
  }
}


@media (min-width: 992px) {
  //@include media-breakpoint-up(lg) {
  .direct {
    //background-color: red;
    // width: 100%;
    height: 100%;
  }

  .advert {
    border: 1px solid white;
    border-radius: 8px;
    height: 134px;
  }

  .pestanas {
    float: top;
    font-size: 19px;
    font-weight: bold;
  }

  .pestanas .detalle {
    list-style-type: none;
    float: right;
    text-align: center;
    font-size: 1.1vw;
    //margin: 0px 15px 0px -0px;
    background: $primary;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 0px 15px 0px 15px;
    cursor: pointer;
  }

  .transmision{
    .pRoom{
      font-size: 12px;
    }
  }

  .pestanas a:link {
    text-decoration: none;
    color: white;
  }

  .chatSpeacher {
    min-height: 50px;
    position: relative;
    padding: 5px;
  }

  .chatSession {
    min-height: 200px;
    position: relative;
    padding: 5px;
  }

  .inputChat {
    background-color: #e8e8eb;
    border: 2px solid white;
    padding: 5px 10% 5px 2%;
  }

  .contentInputChat {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

///////////////////////////////////////
// POPUPS
//////////////////////////////////////

.modalCrop {
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;

  background-color: #2227;

  z-index: 100000;
}

.nonedisplay{
  display:none;
}

.modalCrop .close {
  position: absolute;
  top: 5%;
  right: 5%;

  width: 30px;
  height: 30px;

  font-weight: 800;
  color: #FFF;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #8022be;
  border-radius: 200px;

  cursor: pointer;
}

.modalCrop .modalCont {
  width: 100%;

  margin: auto;

  display: flex;
}

.modalCrop .modalCont .cropImageCont {
  width: 90%;
  min-height: 90%;

  margin: auto;

  display: flex;
  flex-flow: row;
}

.modalCrop .modalCont .cropImageCont .ReactCrop {
  min-width: 50%;
  max-width: 50%;
}

.cropImageCont canvas{
  position: fixed;
  top: 5vw;
  right: 15vw;
}

.cropImageCont .btnSave{
  position: fixed;
  top: 30vw;
  right: 20vw;
}


.modalCrop .modalCont .cropImageCont div {
  margin: auto;
  width: auto;
  height: auto;
}

.modalCrop .modalCont .cropImageCont .ReactCrop canvas {
  min-width: 10%;
  max-width: 10%;

  margin: auto;
}

.modalCrop .modalCont .cropImageCont button {
  margin: auto;
}

/* ======================================================== */

.modal-header {
  border-bottom: 0px;
}

.modal-header .btn-close {
  top: -20px;
  position: relative;
  color: red;
}

.nobtn-close{
  .btn-close{
    display:none;
  }
}

.close {
  color: #fff;
  opacity: 1;
}

.starRating {
  color: $terciario;
  padding: 0px 8px 0px 8px;
  font-size: 2em;
}

//////////////////////////////////////
// modales
/////////////////////////////////////
.modalView {
  position: relative;
  cursor: pointer;
  .backdropAgenda {
    position: fixed;
    background: #2227;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
  }
  .popAgenda {
    position: fixed;
    top: 5%;
    right: 10vw;
    bottom: auto;
    left: 10vw;
    z-index: 9999;

    max-width: 90vw;
    max-height: 90vh;

    .closeAgenda {
      position: absolute;
      top: 0;
      right: 0;

      background-color: transparent;

      border-radius: 200px;
      outline: none;
      border: none;

      font-weight: 800;
      color: #FFF;
      font-size: 30px;

      z-index: 10000;

      cursor: pointer;
    }
  }
  .backdrop {
    position: fixed;
    background: #2227;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
  }
  .pop {
    position: absolute;
    top: 32px;
    right: 2px;
    z-index: 9999;
  }
}

//////////////////////////////
//// Style modalPerfil
/////////////////////////////

.tarjeta {
  position: relative;
  top: 30px;
  display: inline-block;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
  width: 18vw;
  height: 20vw;
  border: 3px solid #8124bd;
  border-radius: 7%;
  background-color: rgba(0, 0, 0, 0.60);
  min-width: 200px;
  min-height: 210px;
  box-shadow: 0 8px 16px 0 rgb(0, 0, 0);
  cursor: default;


  .perfilMod {
    height: auto;
    width: auto;
    text-align: center;
    justify-content: center;
    color: white;
    padding: 5%;
    font-size: 1em;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
  }

  .prof_pic {
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    width: 40%;
    height: 37%;

    .prof_pic2 {
      width: 100%;
      border-radius: 2000px;
      border: 5px solid #8022be;
    }
  }

  .nameCont {

    width: 100%;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 1%;

    h2 {
      color: white;
      font-weight: bold;
      font-size: 1.3vw;
    }
  }


  .puntos {

    position: absolute;
    bottom: 5px;;
    display: flex;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    margin:0;
    padding: 2%;

    .val {

      font-size: 0.9rem;
      color: white;
      flex-grow: 2;
      align-self: flex-end;
      text-align: center;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: bold;  
    }

    .figure {
      flex-shrink: 2;

      .figure2 {
        width: 95%;
      }
    }
  }
}


@media (min-width: 815px) {
  //@include media-breakpoint-up(md) {
  .tarjeta{
    .nameCont {
      h2 {
        font-size: 1.3vw;
      }
    }  
  }
}


////////////////////////
/////AgendaStyle
////////////////////////

.modalAgenda {
  background-color: #2227;
}

.topnav {
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(138, 44, 226, 0.808);
  border: 2px solid rgb(255, 0, 255);
  border-radius: 10px;

  span {
    text-decoration: none;
    padding: 1%;

    button {
      color: rgb(255, 255, 255);
      font-weight: bold;
      font-size: 2em;
      padding: 2%;
    }

    :hover {
      background: none;
      box-shadow: none;
      color: magenta;
    }

    :focus {
      color: white;
      text-decoration: underline;
      background: none;
      box-shadow: none;
    }
  }
}

.agenda {
  background: #FFF;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  margin-top: 1%;
  border-radius: 10px;
  border: 2px solid rgba(255, 0, 255, 0.164);

  .agendaDia {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
